/* Sakura.css v1.4.1
 * ================
 * Minimal css theme.
 * Project: https://github.com/oxalorg/sakura/
 */
/* Body */
html {
  font-size: 62.5%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif; }

body {
  font-size: 1.8rem;
  line-height: 1.618;
  max-width: 38em;
  margin: auto;
  color: rgba(0, 0, 0, 0.85);
  background-color: #ffffff;
  padding: 13px; }

@media (max-width: 684px) {
  body {
    font-size: 1.53rem; } }
@media (max-width: 382px) {
  body {
    font-size: 1.35rem; } }
h1, h2, h3, h4, h5, h6 {
  line-height: 1.1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif;
  font-weight: 700;
  margin-top: 3rem;
  margin-bottom: 1.5rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word; }

h1 {
  font-size: 2.35em; }

h2 {
  font-size: 2em; }

h3 {
  font-size: 1.75em; }

h3.success {
  font-size: 1.75em;
  color: green;
 }

h3.fail {
  font-size: 1.75em;
  color: red;
}

h4 {
  font-size: 1.5em; }

h5 {
  font-size: 1.25em; }

h6 {
  font-size: 1em; }

p {
  margin-top: 0px;
  margin-bottom: 2.5rem; }

small, sub, sup {
  font-size: 75%; }

hr {
  border-color: #3b22ea; }

a {
  text-decoration: none;
  color: #3b22ea; }
  a:visited {
    color: #2913c6; }
  a:hover {
    color: #DA4453;
    border-bottom: 2px solid rgba(0, 0, 0, 0.85); }

ul {
  padding-left: 1.4em;
  margin-top: 0px;
  margin-bottom: 2.5rem; }

li {
  margin-bottom: 0.4em;
  }

li.success {
  margin-bottom: 0.4em;
  color: green;
 }

li.fail {
  margin-bottom: 0.4em;
  color: red;
}

blockquote {
  margin-left: 0px;
  margin-right: 0px;
  padding-left: 1em;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
  padding-right: 0.8em;
  border-left: 5px solid #3b22ea;
  margin-bottom: 2.5rem;
  background-color: #f7f7f7; }

blockquote p {
  margin-bottom: 0; }

img, video {
  height: auto;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 2.5rem; }

/* Pre and Code */
pre {
  background-color: #f7f7f7;
  display: block;
  padding: 1em;
  overflow-x: auto;
  margin-top: 0px;
  margin-bottom: 2.5rem;
  font-size: 0.9em; }

code, kbd, samp {
  font-size: 0.9em;
  padding: 0 0.5em;
  background-color: #f7f7f7;
  white-space: pre-wrap; }

pre > code {
  padding: 0;
  background-color: transparent;
  white-space: pre;
  font-size: 1em; }

/* Tables */
table {
  text-align: justify;
  width: 100%;
  border-collapse: collapse; }

td, th {
  padding: 0.5em;
  border-bottom: 1px solid #f7f7f7; }

/* Buttons, forms and input */
input, textarea {
  border: 1px solid rgba(0, 0, 0, 0.85); }
  input:focus, textarea:focus {
    border: 1px solid #3b22ea; }

textarea {
  width: 100%; }

.button, button, input[type="submit"], input[type="reset"], input[type="button"] {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  background-color: #3b22ea;
  color: #ffffff;
  border-radius: 1px;
  border: 1px solid #3b22ea;
  cursor: pointer;
  box-sizing: border-box; }
  .button[disabled], button[disabled], input[type="submit"][disabled], input[type="reset"][disabled], input[type="button"][disabled] {
    cursor: default;
    opacity: .5; }

  button:active:enabled {
    background-color: #DA4453;
    border-color: #DA4453;
    color: #ffffff;
    outline: 0; }

textarea, select, input {
  color: rgba(0, 0, 0, 0.85);
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  margin-bottom: 10px;
  background-color: #f7f7f7;
  border: 1px solid #f7f7f7;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box; }
  textarea:focus, select:focus, input:focus {
    border: 1px solid #3b22ea;
    outline: 0; }

input[type="checkbox"]:focus {
  outline: 1px dotted #3b22ea; }

label, legend, fieldset {
  display: block;
  margin-bottom: .5rem;
  font-weight: 600; }

pre {
    white-space: pre-wrap;
    word-break: break-all;
}

.Collapsible__trigger {
  display: block;
  font-weight: 400;
  position: relative;
  border: 1px solid white;
  padding: 5px;
  cursor: pointer;
  background: #D3D3D3;
  color: black;
}