/* Modified from https://www.w3schools.com/howto/howto_js_topnav.asp */
/* Add a black background color to the top navigation */
.topnav {
    background-color: #ffffff;
    overflow: hidden;
    position: sticky;
    top: 0;
  }
  
  /* Style the links inside the navigation bar */
  .topnav a {
    float: left;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
  }
  
  /* Change the color of links on hover */
  .topnav a:hover {
    background-color: #ddd;
    color: black;
  }

  .topnav a[role="link"] {
    color: grey;
    cursor: default;
  }
  
  /* Add a color to the active/current link */
  .topnav a.active {
    background-color: #04AA6D;
    color: white;
  }
